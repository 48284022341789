// dev
export const environment = {
  ENV: 'local',
  production: false,
  serverUrl: ['https://d3-api.gosu.bar'],
  webhookUrl: 'https://[botId].d3-api.gosu.bar/[platform]',
  customizeUrl: 'https://[botId].d3-api.gosu.bar/customize/[event]',
  surveyCakeUrl: 'https://[botId].d3-api.gosu.bar/SurveyCake/fill/[svid]',
  surveyCakeThankPageUrl:
    'https://[botId].d3-api.gosu.bar/SurveyCake/thank/[svid]',
  callbackUrl: 'https://[botId].d3-api.gosu.bar/oauth/line/logged',
  profileUrl: 'https://d3-api.gosu.bar/profile',
  richmenuUrl: 'https://d3-api.gosu.bar/richmenu',
  couponUrl: 'https://d3-api.gosu.bar/coupon',
  sharePromotionChannelUrl: 'https://[botId].d3-api.gosu.bar/share/[key]',
  addPromotionChannelUrl: 'https://[botId].d3-api.gosu.bar/add/[key]/[subKey]',
  loginTokenKey: 'daaWJszhLVXbzBRTQkwgQBGQdmkEeaWvFD',
  noPassword: true,
  gtmId: 'GTM-NSSL8WV',
  features: {
    homePage: true,
    changePassword: true,
    marketPlace: true,
    payment: true,
    changeCompany: true,
    companyInfo: true,
  },

  enableThirdPartyLogin: true,
  googleTranslateAPIKey: 'AIzaSyAUIx1EVr7LOPinv0hTJkAt1htvbUlMCAI',
  partner: 'https://d-partner.gosu.bar/',
  sentry: 'https://ba3e2a2d475a45209e0bc875c1a0ae2c@sentry.anyong.com.tw/2',
  directus: {
    url: 'https://directus.gosu.bar',
    project: '2',
  },
  flexTemplates: [],
  gosubarpublic: 'https://gosubarpublic.blob.core.windows.net',
  isCustomize: false,
};

console.log('ENV:', environment.ENV);
